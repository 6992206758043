import {
  FILTER_FOR_ASSESSMENTS,
  FILTER_FOR_BLOCKS,
  FILTER_FOR_ITEMS,
  GET_ALL_FILTERS,
  GET_DOCTYPES,
  NEW_FILTER,
} from "./ActionsTypes";

const initialState = {
  ListFilters: [],
  docTypes: [],
  docClasses: [],
  assessments: {},
  blocks: {},
  items: {},
};

const FiltersReducer = (state = initialState, action) => {
  const { type, payload } = action;
  // eslint-disable-next-line default-case
  switch (type) {
    case GET_ALL_FILTERS:
      return {
        ...state,
        ListFilters: payload.Filters,
        docTypes: payload.Filters.filter((t) => t.type === "doctype"),
        docClasses: payload.Filters.filter((t) => t.type === "docclass"),
      };

    case GET_DOCTYPES:
      return {
        ...state,
        docTypes: payload.docTypes,
      };
    // case GET_FILTER_FOR_ASSESSMENTS:
    //   state.filterOf.assessments = payload
    //   break;

    case FILTER_FOR_ASSESSMENTS:
      return {
        ...state,
        assessments: { ...state.assessments, ...payload },
      };

    case FILTER_FOR_BLOCKS:
      return {
        ...state,
        blocks: payload.blockFilter,
      };

    case FILTER_FOR_ITEMS:
      return {
        ...state,
        items: payload.itemFilter,
      };

    case NEW_FILTER:
      return {
        ...state,
        ...payload,
      };

    // case GET_FILTER_FOR_ITEMS:
    //   state.filterOf.items = payload.
    //   break;
  }
  return state;
};
export default FiltersReducer;
