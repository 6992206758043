import "@fontsource/poppins";
//import "material-icons/css/material-icons.css";
//import "react-virtualized/styles.css";
import "./index.css";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import React, { lazy } from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";

import { Provider } from "react-redux";
//  import App from "./App";
//  import MainContainer from "./Default/Components/MainContainer";
import ReactDOM from "react-dom/client";
import store from "./app/store";
import reportWebVitals from "./reportWebVitals";

const MainContainer = lazy(() => import("./Default/Components/MainContainer"));
const ExergmaeEval2 = lazy(() => import("./Mocap/ExergmaeEval2"));
const SquatTest = lazy(() => import("./Mocap/SquatTest"));

export const isElectron = navigator.userAgent
  .toLowerCase()
  .includes("electron");
if (isElectron) {
  //alert("elctronjs2");
} else {
  // alert("web2"); // Site loaded in a regular browser
}
const root = ReactDOM.createRoot(document.getElementById("root"));
if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
}
// const stripePromise = loadStripe(
//   "pk_test_51O05PxKpm22CkZ8KhRbA0DVJKJuJ8iXusI41c3XzCNhY7XTpRSwwWDa62pmypNacx0ERIjG2le2pSHxzXm0M5K8n000wOf1XCe"
// );

root.render(
  // store call redux store

  // <Elements stripe={stripePromise} options={options}>
  // <CheckoutForm />
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route
            index
            element={
              <React.Suspense fallback={<>...</>}>
                <MainContainer tab={1} />
              </React.Suspense>
            }
          />
          <Route
            path="squat"
            element={
              <React.Suspense fallback={<>...</>}>
                <SquatTest
                  //  updatePos={dataRef}
                  restart={() => {
                    //    setStartGame();
                    setTimeout(() => {
                      //    setStartGame(true);
                    }, 1);
                  }}
                  startGame={true} //{startRealtime.current === true && startGame}
                />
              </React.Suspense>
            }
          />
          <Route
            path="exergame"
            element={
              <React.Suspense fallback={<>...</>}>
                <div style={{ background: "#98C5FFEE" }}>
                  {" "}
                  <ExergmaeEval2
                    //  updatePos={dataRef}
                    restart={() => {
                      //    setStartGame();
                      setTimeout(() => {
                        //    setStartGame(true);
                      }, 1);
                    }}
                    startGame={true} //{startRealtime.current === true && startGame}
                  />
                </div>
              </React.Suspense>
            }
          />

          <Route
            path="newAccount"
            element={
              <React.Suspense fallback={<>...</>}>
                <MainContainer tab={1} />
              </React.Suspense>
            }
          />
          <Route
            path="connexion"
            element={
              <React.Suspense fallback={<>...</>}>
                <MainContainer tab={1} />
              </React.Suspense>
            }
          />
          <Route
            path="reset-password"
            element={
              <React.Suspense fallback={<>...</>}>
                <MainContainer tab={1} />
              </React.Suspense>
            }
          />
          <Route
            path="inscrire"
            element={
              <React.Suspense fallback={<>...</>}>
                <MainContainer tab={1} />
              </React.Suspense>
            }
          />
          <Route
            path="*"
            element={
              <div
                style={{
                  background: "#98C5FFEE",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                  fontFamily: "fantasy",
                  fontWeight: 600,
                  fontSize: 40,
                }}
              >
                404 page introuvable
              </div>
            }
          />
        </Route>
      </Routes>
      {/* <Link to="/squat">sqqq</Link>
      <Link to="/">main</Link>
      <Link to="/exergame">exer</Link>
      <Link to="/sqddduat">nn</Link> */}
      <Outlet />
    </BrowserRouter>
  </Provider>
  // </Elements>
);
serviceWorkerRegistration.unregister();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
