import { configureStore } from "@reduxjs/toolkit";
import AccountReducer from "../Toolbox/RdxManagers/AccountReducer"; //'../co managers/AccountReducer';
import AccountsReducer from "../Toolbox/RdxManagers/AccountsReducer";
import AppManangerReducer from "../Toolbox/RdxManagers/appManager";
import DocumentsReducer from "../Toolbox/RdxManagers/DocumentsReducer";
import ElementsReducer from "../Toolbox/RdxManagers/ElementsReducer";
import FiltersReducer from "../Toolbox/RdxManagers/FiltersReducer";
import MeasuresReducer from "../Toolbox/RdxManagers/MeasuresReducer";
import ModelDocumentsReducer from "../Toolbox/RdxManagers/ModelDocumentsReducer";
import ProfilesReducer from "../Toolbox/RdxManagers/ProfilesReducer";
import ViewsReducer from "../Toolbox/RdxManagers/ViewsReducer";

//   import logger from 'redux-logger'

const store = configureStore({
  reducer: {
    account: AccountReducer,
    profiles: ProfilesReducer,
    accounts: AccountsReducer,
    views: ViewsReducer,
    filters: FiltersReducer,
    measures: MeasuresReducer,
    appmanager: AppManangerReducer,
    elements: ElementsReducer,
    documents: DocumentsReducer,
    modeldocuments: ModelDocumentsReducer,
  },

  devTools: true,
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export default store;
