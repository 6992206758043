import {
  ADD_PROFILE,
  DELETE_PROFILE,
  GET_ALL_MPROFILES,
  GET_ALL_PROFILES,
  OPEN_TAB,
  SELECTED_PROFILE,
  UPDATE_PROFILE,
} from "./ActionsTypes";

const initialState = {
  ListProfiles: [],
  SelectedProfile: null,
  version: 0,
  tab: "0",
  keys: null,
  isNew: null,
};

const ProfilesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  // eslint-disable-next-line default-case
  switch (type) {
    case GET_ALL_PROFILES:
      return { ...state, ListProfiles: payload.profiles };

    case DELETE_PROFILE:
      return {
        ...state,
        ListProfiles: state.ListProfiles.filter(
          (f) => f?._id !== payload?.profileId
        ),
      };
    case ADD_PROFILE:
      return {
        ...state,
        ListProfiles: [...state.ListProfiles, ...payload?.newProfiles],
      };
    case GET_ALL_MPROFILES:
      return {
        ...state,
        ListProfiles: payload.profiles,
        //  version: state?.version + 1,
      };
    case "UPD": {
      return { ...state, version: state?.version + 1 };
    }
    case SELECTED_PROFILE:
      return {
        ...state,
        SelectedProfile: payload.SelectedProfile,
        tab: payload.tab,
        keys: payload?.keys,
        isNew: payload?.isNew,
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        SelectedProfile: { ...state.SelectedProfile, ...payload.updates },
      };
    case OPEN_TAB:
      return {
        ...state,

        tab: payload.tab,
      };
  }
  return state;
};
export default ProfilesReducer;
