// for user connection
export const REGISTER_SUCCESS = "SIGNUP_SUCCESS";
export const REGISTER_FAIL = "SIGNUP_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGGED_PROFILE = "LOGGED_PROFILE";

export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SELECTED_TAB = "SELECTED_TAB";
export const IS_LOGGED_IN = "IS_LOGGED_IN";
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const CONNECTED_ACCOUNT = "CONNECTED_ACCOUNT";

export const GET_ALL_ACCOUNTS = "GET_ALL_ACCOUNTS";
export const GET_SELECTED_ACCOUNT = "GET_SELECTED_ACCOUNT";
export const UPDATE_SELECTED_ACCOUNT = "UPDATE_SELECTED_ACCOUNT";
export const DELETE_SELECTED_ACCOUNT = "DELETE_SELECTED_ACCOUNT";

// list of patients for the connected account (professionals)
export const GET_ALL_USERS = "GET_ALL_USERS";
export const ADD_NEW_USER = "ADD_NEW_USER";
export const DELETE_SELECTED_USER = "DELETE_SELECTED_USER";
export const SELECTED_USER = "GET_SELECTED_USER";

export const GET_ALL_PROFILES = "GET_ALL_PROFILES";
export const GET_ALL_MPROFILES = "GET_ALL_MPROFILES";
export const ADD_NEW_PROFILE = "ADD_NEW_PROFILE";
export const DELETE_PROFILE = "DELETE_PROFILE";
export const SELECTED_PROFILE = "SELECTED_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const OPEN_TAB = "OPEN_TAB";
export const ADD_PROFILE = "ADD_PROFILE";

// list of health professionals for the connected account (professionals)
export const GET_ALL_HPRO = "GET_ALL_HPRO";
export const ADD_NEW_HPRO = "ADD_NEW_HPRO";
export const DELETE_SELECTED_HPRO = "DELETE_SELECTED_HPRO";
export const GET_SELECTED_HPRO = "GET_SELECTED_HPRO";

// list of assesmments model created by the admin or professional
export const GET_ALL_ASSESSMENTS_MODELS = "GET_ALL_ASSESSMENTS_MODELS";

// // list of assesmments model created by the admin or professional
export const GET_ALL_ASSESSMENTS_LIST = "GET_ALL_ASSESSMENTS_LIST";
// export const NEW_ASSESSMENT_LIST= "NEW_ASSESSMENTS_LIST";
export const UPDATE_ASSESSMENT = "UPDATE_ASSESSMENT";
export const DELETE_ASSESSMENT = "DELETE_ASSESSMENT";
export const UPDATE_ASSESSMENT_LIST = "UPDATE_ASSESSMENT_LIST";
export const DELETE_ASSESSMENT_LIST = "DELETE_ASSESSMENT_LIST";
export const RESET_ASSESSMENT_LIST = "RESET_ASSESSMENT_LIST";
export const RESET_ASSESSMENT_LISTS = "RESET_ASSESSMENT_LISTS";
export const NEW_TOPICS = "NEW_TOPICS";

//
export const GET_ALL_BLOCKS = "GET_ALL_BLOCKS";
export const NEW_BLOCKS = "NEW_BLOCK";

//
export const GET_ALL_ITEMS = "GET_ALL_ITEMS";
export const NEW_ITEMS = "NEW_ITEMS";

// views

export const GET_ALL_VIEWS = "GET_ALL_VIEWS";
export const NEW_VIEW = "NEW_VIEW";
export const REMOVE_VIEW = "REMOVE_VIEW";
export const UPDATE_VIEW = "UPDATE_VIEW";
//
export const GET_ALL_ELEMENTS = "GET_ALL_ELEMENTS";
export const NEW_ELEMENT = "NEW_ELEMENT";
export const ADD_ELEMENT = "ADD_ELEMENT";

export const UPDATE_ELEMENTS = "UPDATE_ELEMENTS";
export const SORT_ELEMENTS = "SORT_ELEMENTS";
export const UPDATE_ONE_ELEMENT = "UPDATE_ONE_ELEMENT";
export const UPDATE_SELECTED_ELEMENT = "UPDATE_SELECTED_ELEMENT";
export const CANCEL_SELECTED_ELEMENT = "CANCEL_SELECTED_ELEMENT";
export const REMOVE_ELEMENT = "REMOVE_ELEMENT";
export const EMPTY_ELEMENTS = "EMPTY_ELEMENTS";

///////
//
export const GET_ALL_DOCUMENTS = "GET_ALL_DOCUMENTS";
export const GET_ALL_DATEDOCUMENTS = "GET_ALL_DATEDOCUMENTS";
export const GET_ALL_MYTOPICS = "GET_ALL_MYTOPICS";
export const NEW_DOCUMENTS = "NEW_DOCUMENTS";
export const NEW_DOCUMENT_FILE = "NEW_DOCUMENT_FILE";
export const UPDATE_DOCUMENT = "UPDATE_DOCUMENT";
export const EMPTY_DOCUMENTS = "EMPTY_DOCUMENTS";
export const UPDATE_ONE_DOCUMENT = "UPDATE_ONE_DOCUMENT";
export const CANCEL_ONE_DOCUMENT = "CANCEL_ONE_DOCUMENT";
export const CANCEL_DOCUMENTS_BYTOPIC = "CANCEL_DOCUMENTS_BYTOPIC";
export const REMOVE_DOCUMENT = "REMOVE_DOCUMENT";
export const ADD_NEW_ELEMENT_DOCUMENT = "ADD_NEW_ELEMENT_DOCUMENT";
export const UPDATE_ELEMENT_DOCUMENT = "UPDATE_ELEMENT_DOCUMENT";
export const SORT_ELEMENTS_DOCUMENT = "SORT_ELEMENTS_DOCUMENT";
export const REMOVE_ELEMENT_DOCUMENT = "REMOVE_ELEMENT_DOCUMENT";
export const UPDATE_DATA_ELEMENT_DOCUMENT = "UPDATE_DATA_ELEMENT_DOCUMENT";

export const GET_ALL_MODEL_DOCUMENTS = "GET_ALL_MODEL_DOCUMENTS";
export const GET_ALL_MPROFILE_DOCUMENTS = "GET_ALL_MPROFILE_DOCUMENTS";
//export const GET_ALL_MYTOPICS= "GET_ALL_MYTOPICS";
export const NEW_MODEL_DOCUMENTS = "NEW_MODEL_DOCUMENTS";
export const NEW_MODEL_DOCUMENT_FILE = "NEW_MODEL_DOCUMENT_FILE";
export const UPDATE_MODEL_DOCUMENTS = "UPDATE_MODEL_DOCUMENTS";
export const UPDATE_ONE_MODEL_DOCUMENT = "UPDATE_ONE_MODEL_DOCUMENT";
export const REMOVE_MODEL_DOCUMENT = "REMOVE_MODEL_DOCUMENT";
export const CANCEL_ONE_MDOCUMENT = "CANCEL_ONE_MDOCUMENT";
export const CANCEL_MDOCUMENTS_BYTITLE = "CANCEL_MDOCUMENTS_BYTITLE";
export const ADD_NEW_ELEMENT_MODEL_DOCUMENT = "ADD_NEW_ELEMENT_MODEL_DOCUMENT";
export const UPDATE_ELEMENT_MODEL_DOCUMENT = "UPDATE_ELEMENT_MODEL_DOCUMENT";
export const SORT_ELEMENTS_MODEL_DOCUMENT = "SORT_ELEMENTS_MODEL_DOCUMENT";
export const REMOVE_ELEMENT_MODEL_DOCUMENT = "REMOVE_ELEMENT_MODEL_DOCUMENT";
export const UPDATE_DATA_ELEMENT_MODEL_DOCUMENT =
  "UPDATE_DATA_ELEMENT_MODEL_DOCUMENT";

//
export const GET_ALL_COMPONENTS = "GET_ALL_COMPONENTS"; //get all components
export const NEW_COMPONENT = "NEW_COMPONENT"; // create new assessment

//
export const GET_ALL_FILTERS = "GET_ALL_FILTERS";
export const GET_DOCTYPES = "GET_DOCTYPES";
export const FILTER_FOR_BLOCKS = "FILTER_FOR_BLOCKS";
export const FILTER_FOR_ITEMS = "FILTER_FOR_ITEMS";
export const FILTER_FOR_ASSESSMENTS = "FILTER_FOR_ASSESSMENTS";
export const NEW_FILTER = "NEW_FILTER";

//

export const GET_ALL_MEASURES = "GET_ALL_MEASURES";
//
export const GET_ALL_PROFESSIONS = "GET_ALL_PROFESSIONS";

// create new assessment
export const NEW_ASSESSMENT = "NEW_ASSESSMENT"; //only title
export const ADD_BLOCKS_TO_ASSESSMENT = "ADD_BLOCKS_TO_ASSESSMENT"; //add block the created assesmment
export const ADD_ITEMS_TO_B_ASSESSMENT = "ADD_ITEMS_TO_B_ASSESSMENT"; //add item the selected block for the created assesmment
export const ADD_COMPONENTS_TO_I_B_ASSESSMENT =
  "ADD_COMPONENTS_TO_I_B_ASSESSMENT"; //add compoents to  the selected itemm

export const UPDATE_ITEM_OF_ASSESSMENT_LIST = "UPDATE_ITEM_OF_ASSESSMENT_LIST";
export const UPDATE_BLOCK_OF_ASSESSMENT_LIST =
  "UPDATE_BLOCK_OF_ASSESSMENT_LIST";
export const UPDATE_COMP_OF_ASSESSMENT_LIST = "UPDATE_COMP_OF_ASSESSMENT_LIST";

export const SORT_BLOCKS_OF_ASSESSMENT = "SORT_BLOCKS_OF_ASSESSMENT";
export const SORT_ITEMS_OF_ASSESSMENT = "SORT_ITEMS_OF_ASSESSMENT";
export const SORT_COMPS_OF_ASSESSMENT = "SORT_COMPS_OF_ASSESSMENT";
export const REMOVE_BLOCK_FROM_ASSESSMENT = "REMOVE_BLOCK_FROM_ASSESSMENT";
export const REMOVE_ITEM_FROM_ASSESSMENT = "REMOVE_ITEM_FROM_ASSESSMENT";
export const UPDATE_COMPONENTS_ASSESSMENT = "UPDATE_COMPONENTS_ASSESSMENT";
export const REMOVE_COMPONENT_FROM_ASSESSMENT =
  "REMOVE_COMPONENT_FROM_ASSESSMENT";

// create new topics
export const NEW_ASSESSMENT_LIST = "NEW_ASSESSMENT_LIST"; //only title
export const ADD_BLOCKS_TO_ASSESSMENT_LIST = "ADD_BLOCKS_TO_ASSESSMENT_LIST"; //add block the created assesmment
export const ADD_ITEMS_TO_B_ASSESSMENT_LIST = "ADD_ITEMS_TO_B_ASSESSMENT_LIST"; //add item the selected block for the created assesmment
export const ADD_COMPONENTS_TO_I_B_ASSESSMENT_LIST =
  "ADD_COMPONENTS_TO_I_B_ASSESSMENT_LIST"; //add compoents to  the selected itemm

export const SORT_BLOCKS_OF_ASSESSMENT_LIST = "SORT_BLOCKS_OF_ASSESSMENT_LIST";
export const SORT_ITEMS_OF_ASSESSMENT_LIST = "SORT_ITEMS_OF_ASSESSMENT_LIST";
export const SORT_COMPS_OF_ASSESSMENT_LIST = "SORT_COMPS_OF_ASSESSMENT_LIST";
export const REMOVE_BLOCK_FROM_ASSESSMENT_LIST =
  "REMOVE_BLOCK_FROM_ASSESSMENT_LIST";
export const REMOVE_ITEM_FROM_ASSESSMENT_LIST =
  "REMOVE_ITEM_FROM_ASSESSMENT_LIST";
export const UPDATE_COMPONENTS_ASSESSMENT_LIST =
  "UPDATE_COMPONENTS_ASSESSMENT_LIST";
export const REMOVE_COMPONENT_FROM_ASSESSMENT_LIST =
  "REMOVE_COMPONENT_FROM_ASSESSMENT_LIST";

// create new assessment
export const SELECTED_TARGET = "SELECTED_TARGET"; // assessment block item
export const SELECTED_MODE = "SELECTED_MODE"; // assessment block item
export const NOTIFICATION = "NOTIFICATION"; // to show notifcation alert
export const UPDATE_FILTER = "UPDATE_FILTER"; // to filter the assessments used for such diagnosis
export const LEFT_BAR_WIDTH = "LEFT_BAR_WIDTH"; //
export const RIGHT_BAR_WIDTH = "RIGHT_BAR_WIDTH"; //
