import {
  LEFT_BAR_WIDTH,
  NOTIFICATION,
  RIGHT_BAR_WIDTH,
  SELECTED_MODE,
  SELECTED_TAB,
  SELECTED_TARGET,
} from "./ActionsTypes";

const initialState = {
  selectedTab: "Patients",
  selectedAssessment: null,
  selectedBlock: null,
  selectedItem: null,
  selectedComponent: null,
  selectedTopic: null,
  time: 0,
  isActive: false,
  myPrompt: {
    isOpenP: false,
    myMessage: "",
    type: "text",
    myValue: null,
  },
  searchText: "",
  loading: { open: false, message: "" },
  alertNotification: {
    isOpen: false,
    message: null,
    type: null, // success, warning, info, error
    position: {
      vertical: "bottom",
      horizontal: "left",
    },
  },
  //////////////////
  mode: "view",
  target: null,
  leftBarWidth: 300,
  rightBarWidth: 0,
  data: null,
};

const AppManangerReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SEARCH":
      return { ...state, searchText: payload.searchText };
    case "LOADING":
      return {
        ...state,
        loading: payload.loading,
      };
    case SELECTED_TARGET:
      return {
        ...state,
        ...payload,
      };
    case SELECTED_TAB:
      return {
        ...state,
        selectedTab: payload.selectedTab,
      };

    case "data": {
      return {
        ...state,

        data: payload,
      };
    }
    case "myPrompt":
      return {
        ...state,
        myPrompt: {
          ...state.myPrompt,
          ...payload,
        },
      };
    case SELECTED_MODE:
      return {
        ...state,
        ...payload,
      };
    case NOTIFICATION:
      return {
        ...state,
        ...payload,
      };

    case "TIME":
      return {
        ...state,
        ...payload,
      };

    case LEFT_BAR_WIDTH:
      return { ...state, leftBarWidth: payload.leftBarWidth };
    case RIGHT_BAR_WIDTH:
      return { ...state, rightBarWidth: payload.rightBarWidth };
    default:
  }
  return state;
};
export default AppManangerReducer;
