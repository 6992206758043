import {
  ADD_NEW_ELEMENT_DOCUMENT,
  CANCEL_DOCUMENTS_BYTOPIC,
  CANCEL_ONE_DOCUMENT,
  EMPTY_DOCUMENTS,
  GET_ALL_DATEDOCUMENTS,
  GET_ALL_DOCUMENTS,
  GET_ALL_MYTOPICS,
  NEW_DOCUMENTS,
  NEW_DOCUMENT_FILE,
  REMOVE_DOCUMENT,
  REMOVE_ELEMENT_DOCUMENT,
  SORT_ELEMENTS_DOCUMENT,
  UPDATE_DATA_ELEMENT_DOCUMENT,
  UPDATE_DOCUMENT,
  UPDATE_ELEMENT_DOCUMENT,
  UPDATE_ONE_DOCUMENT,
} from "./ActionsTypes";

const initialState = {
  AllMyDocumentsByTopic: [],
  encData: [],
};

const DocumentsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  // eslint-disable-next-line default-case
  switch (type) {
    case "aa":
      return {
        ...state,
      };
    case GET_ALL_DATEDOCUMENTS:
      return {
        ...state,
        encData: payload.documents,
      };

    case "ENCDATA":
      return {
        ...state,
        encData: payload.documents,
      };
    case GET_ALL_DOCUMENTS:
      return {
        ...state,
        //  encData: payload.documents,
        AllMyDocumentsByTopic: payload.documents, //todo // getDocsbyTopicTElements(payload.documents),
        // AllMyDocumentsByType: getDocsbyTypeElements(payload.documents),
      };
    case GET_ALL_MYTOPICS:
      return {
        ...state,

        myTopics: payload.mytopics,
      };

    case REMOVE_DOCUMENT:
      return {
        ...state,
        encData: state.encData.filter((f) => f?.documentID !== payload?.docId),
      };
    case UPDATE_DOCUMENT:
      return {
        ...state,

        DocumentsLists: [{ ...payload.content }],
      };

    case UPDATE_ONE_DOCUMENT:
      //   alert(JSON.stringify(payload))
      return {
        ...state,
        encData: state.encData.map((document) =>
          document.documentID === payload.doc?.documentID
            ? {
                ...payload.doc,
              }
            : document
        ),
      };
    case NEW_DOCUMENTS:
      return {
        ...state,
        encData: [...payload.documents, ...state.encData],
      };
    case CANCEL_ONE_DOCUMENT:
      return {
        ...state,
        DocumentsLists: state.DocumentsLists.filter(
          (document, index) => index !== payload.docIndex
        ),
      };

    case NEW_DOCUMENT_FILE:
      return {
        ...state,
        files: [...state.files, payload.files],
      };

    case CANCEL_DOCUMENTS_BYTOPIC:
      return {
        ...state,
        DocumentsLists: state.DocumentsLists.filter(
          (document) => payload.docTopic !== document.topic
        ),
      };

    case EMPTY_DOCUMENTS:
      return {
        ...state,
        DocumentsLists: [],
        AllMyDocumentsByTopic: [],
        encData: [],
      };

    case UPDATE_ELEMENT_DOCUMENT:
      // alert(JSON.stringify(payload));
      return {
        ...state,
        ////////////////////////////

        DocumentsLists: state.DocumentsLists.map((docs, i) =>
          i === payload.topicIndex
            ? {
                ...docs,
                ///////////////
                elements: docs.elements.map((elem) =>
                  elem.id === payload.elementIndex
                    ? {
                        ...elem,

                        ...payload.content,
                      }
                    : elem
                ),

                /////////////
              }
            : docs
        ),
        //////////////
      };

    case SORT_ELEMENTS_DOCUMENT:
      return {
        ...state,
        DocumentsLists: state.DocumentsLists.map((document, i) =>
          i === payload.topicIndex
            ? { ...document, elements: payload.content }
            : document
        ),
      };
    case UPDATE_DATA_ELEMENT_DOCUMENT:
      // alert(JSON.stringify(payload));
      return {
        ...state,
        ////////////////////////////

        DocumentsLists: state.DocumentsLists.map((docs, i) =>
          i === payload.topicIndex
            ? {
                ...docs,
                ///////////////
                elements: docs.elements.map((elem) =>
                  elem.id === payload.elementIndex
                    ? {
                        ...elem,

                        ...payload.values,
                      }
                    : elem
                ),

                /////////////
              }
            : docs
        ),
        //////////////
      };

    case ADD_NEW_ELEMENT_DOCUMENT:
      // alert(JSON.stringify(payload));
      return {
        ...state,
        ////////////////////////////

        DocumentsLists: state.DocumentsLists.map((docs, i) =>
          i === payload.topicIndex
            ? {
                ...docs,
                ///////////////
                elements: [...docs.elements, payload.content],

                /////////////
              }
            : docs
        ),
        //////////////
      };

    case REMOVE_ELEMENT_DOCUMENT:
      return {
        ...state,
        DocumentsLists: state.DocumentsLists.map((docs, i) =>
          i === payload.topicIndex
            ? {
                ...docs,
                elements: docs.elements.filter(
                  (element) => element.id !== payload.elementIndex
                ),
              }
            : docs
        ),
      };
  }

  return state;
};
export default DocumentsReducer;
