import {
  ADD_ELEMENT,
  CANCEL_SELECTED_ELEMENT,
  EMPTY_ELEMENTS,
  GET_ALL_ELEMENTS,
  NEW_ELEMENT,
  REMOVE_ELEMENT,
  SORT_ELEMENTS,
  UPDATE_ELEMENTS,
  UPDATE_ONE_ELEMENT,
  UPDATE_SELECTED_ELEMENT,
} from "./ActionsTypes";

const initialState = { ElementsList: [], MElement: null, BuildElements: [] };

const ElementsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  // eslint-disable-next-line default-case
  switch (type) {
    case GET_ALL_ELEMENTS:
      return {
        ...state,

        ElementsList: payload.elements,
      };

    case UPDATE_ELEMENTS:
      return {
        ...state,

        ElementsList: payload,
      };

    case UPDATE_ONE_ELEMENT:
      //   alert(JSON.stringify(payload))
      return {
        ...state,
        MElement: {
          ...state.MElement,
          ...payload.content,
        },
      };
    case NEW_ELEMENT:
      return {
        ...state,
        MElement: {
          ...payload.content,
        },
      };
    case SORT_ELEMENTS:
      return {
        ...state,
        BuildElements: payload.content,
      };

    case REMOVE_ELEMENT:
      return {
        ...state,
        BuildElements: state.BuildElements.filter(
          (element, index) => element.id !== payload.elementId
        ),
      };

    case EMPTY_ELEMENTS:
      return {
        ...state,
        BuildElements: [],
      };
    case "UPDATE_ELEMENT": {
      const index = state.ElementsList.findIndex(
        (todo) => todo.id === payload.elementId
      );
      const newArray = [...state.ElementsList]; //making a new array
      newArray[index] = payload.content; //changing value in the new array
      return {
        ...state, //copying the orignal state
        ElementsList: newArray, //reassingning todos to new array
      };
    }
    //   alert(JSON.stringify(payload))
    // return {
    //   ...state,
    //   ElementsList: state.ElementsList.map((element, i) =>
    //     i=== payload.elementId
    //       ? {
    //           ...element,
    //           ...payload.content,
    //         }
    //       : element
    //   ),
    // };

    case UPDATE_SELECTED_ELEMENT:
      //   alert(JSON.stringify(payload))
      return {
        ...state,
        BuildElements: state.BuildElements.map((element, i) =>
          element.id === payload.elementId
            ? {
                ...element,
                ...payload.content,
              }
            : element
        ),
      };
    case ADD_ELEMENT:
      return {
        ...state,
        BuildElements: [...state.BuildElements, { ...payload.elements }],
      };
    case CANCEL_SELECTED_ELEMENT:
      return {
        ...state,
        BuildElements: state.BuildElements.filter(
          (element, index) => index !== payload.elementId
        ),
      };
  }
  return state;
};
export default ElementsReducer;
