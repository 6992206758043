import { LOGGED_PROFILE, LOGIN_SUCCESS, LOGOUT } from "./ActionsTypes";

const initialState = { isLoggedIn: false, LoggedUser: null };

const AccountReducer = (state = initialState, action) => {
  const { type, payload } = action;
  // eslint-disable-next-line default-case
  switch (type) {
    case LOGIN_SUCCESS:
      state = {
        isLoggedIn: true,
        LoggedUser: payload,
      };
      break;
    case LOGGED_PROFILE:
      state = {
        isLoggedIn: true,
        LoggedUser: {
          ...state.LoggedUser,
          loggedProfile: payload.loggedProfile,
        },
      };
      break;
    case LOGOUT:
      state = {
        isLoggedIn: false,
        LoggedUser: null,
      };
      break;
    case "UPDATE_USER":
      return {
        ...state,
        LoggedUser: { ...state.LoggedUser, ...payload.updates },
      };
  }
  return state;
};
export default AccountReducer;
