import { GET_ALL_ACCOUNTS, UPDATE_SELECTED_ACCOUNT } from "./ActionsTypes";

const initialState = { AllAccounts: [] };

const AccountsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  // eslint-disable-next-line default-case
  switch (type) {
    case GET_ALL_ACCOUNTS:
      return { ...state, AllAccounts: payload.accounts };

    case UPDATE_SELECTED_ACCOUNT: {
      const index = state.AllAccounts.findIndex(
        (todo) => todo._id === payload.accountId
      );
      const newArray = [...state.AllAccounts]; //making a new array
      newArray[index] = payload.content; //changing value in the new array
      return {
        ...state, //copying the orignal state
        AllAccounts: newArray, //reassingning todos to new array
      };
    }
  }
  return state;
};
export default AccountsReducer;
