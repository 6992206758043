import {
  ADD_NEW_ELEMENT_MODEL_DOCUMENT,
  CANCEL_MDOCUMENTS_BYTITLE,
  CANCEL_ONE_MDOCUMENT,
  GET_ALL_MODEL_DOCUMENTS,
  GET_ALL_MPROFILE_DOCUMENTS,
  NEW_MODEL_DOCUMENTS,
  NEW_MODEL_DOCUMENT_FILE,
  REMOVE_ELEMENT_MODEL_DOCUMENT,
  REMOVE_MODEL_DOCUMENT,
  SORT_ELEMENTS_MODEL_DOCUMENT,
  UPDATE_DATA_ELEMENT_MODEL_DOCUMENT,
  UPDATE_ELEMENT_MODEL_DOCUMENT,
  UPDATE_MODEL_DOCUMENTS,
  UPDATE_ONE_MODEL_DOCUMENT,
} from "./ActionsTypes";

const initialState = {
  AllMyMDocuments: [],
  MDocumentsLists: [],
  MDocProfile: [],
  files: [],
};

const ModelDocumentsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  // eslint-disable-next-line default-case
  switch (type) {
    case GET_ALL_MODEL_DOCUMENTS:
      return {
        ...state,

        AllMyMDocuments: payload.documents,
      };
    case GET_ALL_MPROFILE_DOCUMENTS:
      return {
        ...state,

        MDocProfile: payload.documents,
      };
    case UPDATE_MODEL_DOCUMENTS:
      return {
        ...state,

        MDocumentsLists: [{ ...payload.documents }],
      };

    case UPDATE_ONE_MODEL_DOCUMENT:
      //   alert(JSON.stringify(payload))
      return {
        ...state,
        MDocumentsLists: state.MDocumentsLists.map((document, i) =>
          i === payload.topicIndex
            ? {
                ...document,
                ...payload.content,
              }
            : document
        ),
      };
    case NEW_MODEL_DOCUMENTS:
      return {
        ...state,
        MDocumentsLists: [...state.MDocumentsLists, { ...payload.documents }],
      };
    case NEW_MODEL_DOCUMENT_FILE:
      return {
        ...state,
        files: [...state.files, payload.files],
      };

    case REMOVE_MODEL_DOCUMENT:
      return {
        ...state,
        MDocumentsLists: state.MDocumentsLists.filter(
          (document) => document.title !== payload.selectedDocument
        ),
      };

    case CANCEL_ONE_MDOCUMENT:
      return {
        ...state,
        MDocumentsLists: state.MDocumentsLists.filter(
          (mdocument, index) => index !== payload.docIndex
        ),
      };

    case CANCEL_MDOCUMENTS_BYTITLE:
      return {
        ...state,
        MDocumentsLists: state.MDocumentsLists.filter(
          (document, index) => payload.docTitle !== document.title
        ),
      };

    case UPDATE_ELEMENT_MODEL_DOCUMENT:
      // alert(JSON.stringify(payload));
      return {
        ...state,
        ////////////////////////////

        MDocumentsLists: state.MDocumentsLists.map((docs, i) =>
          i === payload.topicIndex
            ? {
                ...docs,
                ///////////////
                elements: docs.elements.map((elem, j) =>
                  elem.id === payload.elementIndex
                    ? {
                        ...elem,

                        ...payload.content,
                      }
                    : elem
                ),

                /////////////
              }
            : docs
        ),
        //////////////
      };

    case SORT_ELEMENTS_MODEL_DOCUMENT:
      // alert(JSON.stringify(payload))
      return {
        ...state,
        MDocumentsLists: state.MDocumentsLists.map((document, i) =>
          i === payload.topicIndex
            ? { ...document, elements: payload.content }
            : document
        ),
      };
    case UPDATE_DATA_ELEMENT_MODEL_DOCUMENT:
      // alert(JSON.stringify(payload));
      return {
        ...state,
        ////////////////////////////

        MDocumentsLists: state.MDocumentsLists.map((docs, i) =>
          i === payload.topicIndex
            ? {
                ...docs,
                ///////////////
                elements: docs.elements.map((elem, j) =>
                  elem.id === payload.elementIndex
                    ? {
                        ...elem,

                        ...payload.values,
                      }
                    : elem
                ),

                /////////////
              }
            : docs
        ),
        //////////////
      };

    case ADD_NEW_ELEMENT_MODEL_DOCUMENT:
      // alert(JSON.stringify(payload));
      return {
        ...state,
        ////////////////////////////

        MDocumentsLists: state.MDocumentsLists.map((docs, i) =>
          i === payload.documentIndex
            ? {
                ...docs,
                ///////////////
                elements: [...docs.elements, payload.content],

                /////////////
              }
            : docs
        ),
        //////////////
      };

    case REMOVE_ELEMENT_MODEL_DOCUMENT:
      return {
        ...state,
        MDocumentsLists: state.MDocumentsLists.map((docs, i) =>
          i === payload.topicIndex
            ? {
                ...docs,
                elements: docs.elements.filter(
                  (element) => element.id !== payload.elementIndex
                ),
              }
            : docs
        ),
      };
  }

  return state;
};
export default ModelDocumentsReducer;
