import {
  GET_ALL_VIEWS,
  NEW_VIEW,
  REMOVE_VIEW,
  UPDATE_VIEW,
} from "./ActionsTypes";

const initialState = { viewsList: [] };

const ViewsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  // eslint-disable-next-line default-case
  switch (type) {
    case GET_ALL_VIEWS:
      return {
        ...state,

        viewsList: payload.views,
      };
    case NEW_VIEW:
      return {
        ...state,
        viewsList: [...state.viewsList, { ...payload.newView }],
      };
    case REMOVE_VIEW:
      return {
        ...state,
        viewsList: state.viewsList.filter(
          (view, index) => view.viewId !== payload.viewId
        ),
      };

    case UPDATE_VIEW: {
      const index = state.viewsList.findIndex(
        (todo) => todo.viewId === payload.viewId
      );
      const newArray = [...state.viewsList]; //making a new array
      newArray[index] = payload.content; //changing value in the new array
      return {
        ...state, //copying the orignal state
        viewsList: newArray, //reassingning todos to new array
      };
    }
  }
  return state;
};
export default ViewsReducer;
