import {
  FILTER_FOR_ASSESSMENTS,
  FILTER_FOR_BLOCKS,
  FILTER_FOR_ITEMS,
  GET_ALL_MEASURES,
  GET_DOCTYPES,
  NEW_FILTER,
} from "./ActionsTypes";

const initialState = {
  ListMeasures: [],
  docTypes: [],
  assessments: {},
  blocks: {},
  items: {},
};

const MeasuresReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_MEASURES:
      return {
        ...state,
        ListMeasures: payload.Measures,
      };

    case GET_DOCTYPES:
      return {
        ...state,
        docTypes: payload.docTypes,
      };
    // case GET_FILTER_FOR_ASSESSMENTS:
    //   state.filterOf.assessments = payload
    //   break;

    case FILTER_FOR_ASSESSMENTS:
      return {
        ...state,
        assessments: { ...state.assessments, ...payload },
      };

    case FILTER_FOR_BLOCKS:
      return {
        ...state,
        blocks: payload.blockFilter,
      };

    case FILTER_FOR_ITEMS:
      return {
        ...state,
        items: payload.itemFilter,
      };

    case NEW_FILTER:
      return {
        ...state,
        ...payload,
      };

    // case GET_FILTER_FOR_ITEMS:
    //   state.filterOf.items = payload.
    //   break;
  }
  return state;
};
export default MeasuresReducer;
